var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-4 w-full dF fC f1 pb-4 hide-scrollbar",staticStyle:{"overflow-y":"scroll"}},[_c('div',{staticClass:"dF jE mb-4"},[_c('div',{staticClass:"dF"},[_c('div',[_c('a-input',{ref:"searchInput",staticStyle:{"width":"300px"},attrs:{"placeholder":"Search broadcast...","size":"large"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"search"},slot:"prefix"})],1)],1)])]),_c('a-card',{staticClass:"table-card",staticStyle:{"padding":"0"}},[_c('a-table',{staticClass:"tableStyle",attrs:{"columns":_vm.columns,"data-source":_vm.broadcasts,"row-key":(record) => record.id,"loading":_vm.loading,"pagination":{
                    current: _vm.currentPage,
                    total: _vm.totalBroadcasts,
                    pageSize: _vm.pageSize,
                }},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"name",fn:function(record){return _c('div',{staticClass:"dF fC"},[_vm._v(" "+_vm._s(record.name)+" "),_c('span',{staticStyle:{"color":"var(--med-gray)"}},[_c('a-tooltip',{attrs:{"overlayClassName":"change-tooltip-color","title":`Subject: ${record.subject}`}},[_vm._v(" Subject: "+_vm._s(_vm.trim(record.subject, 50))+" ")]),_c('br'),(record.initialBroadcast && _vm.getBroadcastName(record.initialBroadcast))?_c('span',[_vm._v("Previous Broadcast: "+_vm._s(_vm.getBroadcastName(record.initialBroadcast))+" ")]):_vm._e()],1)])}},{key:"instanceName",fn:function(record){return _c('div',{},[_vm._v(" "+_vm._s(_vm.getInstanceName(record.instance))+" ")])}},{key:"sent",fn:function(record){return _c('div',{staticClass:"dF"},[_vm._v(" "+_vm._s(_vm.formatDate( (record.counts && record.counts.sent) || record.createdAt ))+" ")])}},{key:"delivered",fn:function(record){return _c('div',{staticClass:"dF"},[_vm._v(" "+_vm._s((record.counts && record.counts.totalSent) || 0)+" ")])}},{key:"opened",fn:function(record){return _c('div',{staticClass:"dF"},[_vm._v(" "+_vm._s((record.counts && record.counts.opened) || 0)+" ")])}},{key:"openRate",fn:function(record){return _c('div',{staticClass:"dF"},[(record.counts &&
						record.counts.opened &&
						record.counts.delivered
						)?_c('span',[_vm._v(" "+_vm._s(Math.floor( (record.counts.opened / record.counts.delivered) * 100 ))+"% ")]):_c('span',{staticClass:"text-med-gray"},[_vm._v(" 0% ")])])}},{key:"ctr",fn:function(record){return _c('div',{staticClass:"dF"},[(record.counts &&
							record.counts.clicked &&
							record.counts.delivered
							)?_c('span',[_vm._v(" "+_vm._s(Math.floor( (record.counts.clicked / record.counts.delivered) * 100 ))+"% ")]):_c('span',{staticClass:"text-med-gray"},[_vm._v(" 0% ")])])}},{key:"bounceRate",fn:function(record){return _c('div',{staticClass:"dF"},[(record.counts)?_c('span',[_vm._v(" "+_vm._s(_vm.bounceRate(record))+"% ")]):_c('span',{staticClass:"text-med-gray"},[_vm._v(" 0% ")])])}},{key:"actions",fn:function(record){return _c('div',{staticClass:"dF aC",staticStyle:{"gap":"10px"}},[_c('a-button',{on:{"click":function($event){return _vm.previewEmail(record.prodHtml)}}},[_vm._v("Preview Email")]),_c('a-button',{on:{"click":function($event){return _vm.$router.push(`/email/${record.id}`)}}},[_vm._v("View Report")])],1)}}])})],1),_c('a-modal',{attrs:{"title":"Preview Email","footer":null,"width":700},model:{value:(_vm.previewEmailModal),callback:function ($$v) {_vm.previewEmailModal=$$v},expression:"previewEmailModal"}},[_c('iframe',{staticClass:"email-quick-preview"})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }