<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div class="dF jE mb-4">
			<div class="dF">
				<div>
					<a-input ref="searchInput" v-model="search" placeholder="Search broadcast..." style="width: 300px"
						size="large">
						<a-icon slot="prefix" type="search" />
					</a-input>
				</div>
			</div>
		</div>
		<a-card style="padding: 0" class="table-card">
			<a-table class="tableStyle" :columns="columns" :data-source="broadcasts" :row-key="(record) => record.id"
				:loading="loading"
                :pagination="{
                    current: currentPage,
                    total: totalBroadcasts,
                    pageSize: pageSize,
                }"
                @change="handleChange">
				<div slot="name" slot-scope="record" class="dF fC">
					{{ record.name }}
					<span style="color: var(--med-gray)">
						<a-tooltip overlayClassName="change-tooltip-color" :title="`Subject: ${record.subject}`">
							Subject: {{ trim(record.subject, 50) }}
						</a-tooltip>
						<br>
						<span v-if="record.initialBroadcast && getBroadcastName(record.initialBroadcast)">Previous
							Broadcast: {{ getBroadcastName(record.initialBroadcast) }} </span>
					</span>
				</div>
				<div slot="instanceName" slot-scope="record">
					{{ getInstanceName(record.instance) }}
				</div>
				<div slot="sent" slot-scope="record" class="dF">
					{{
						formatDate(
							(record.counts && record.counts.sent) ||
							record.createdAt
						)
					}}
				</div>
				<div slot="delivered" slot-scope="record" class="dF">
					{{ (record.counts && record.counts.totalSent) || 0 }}
				</div>
				<div slot="opened" slot-scope="record" class="dF">
					{{ (record.counts && record.counts.opened) || 0 }}
				</div>
				<div slot="openRate" slot-scope="record" class="dF">
					<span v-if="record.counts &&
						record.counts.opened &&
						record.counts.delivered
						">
						{{
							Math.floor(
								(record.counts.opened /
									record.counts.delivered) *
								100
							)
						}}%
					</span>
					<span v-else class="text-med-gray"> 0% </span>
				</div>

				<div slot="ctr" slot-scope="record" class="dF">
					<span v-if="record.counts &&
							record.counts.clicked &&
							record.counts.delivered
							">
						{{
							Math.floor(
								(record.counts.clicked /
									record.counts.delivered) *
								100
							)
						}}%
					</span>
					<span v-else class="text-med-gray"> 0% </span>
				</div>
				<div slot="bounceRate" slot-scope="record" class="dF">
					<span v-if="record.counts">
						{{ bounceRate(record) }}%
					</span>
					<span v-else class="text-med-gray"> 0% </span>
				</div>

				<div slot="actions" slot-scope="record" class="dF aC" style="gap: 10px;">
					<a-button @click="previewEmail(record.prodHtml)">Preview Email</a-button>
					<a-button @click="$router.push(`/email/${record.id}`)">View Report</a-button>
				</div>
			</a-table>
		</a-card>
		<a-modal title="Preview Email" v-model="previewEmailModal" :footer="null" :width="700">
			<iframe class="email-quick-preview" />
		</a-modal>
	</div>
</template>

<script>
import { formatDate } from "bh-mod";
import _ from "lodash";

export default {
	components: {},
	data() {
		return {
			loading: false,
			previewEmailModal: false,
			search: "",
			sortedInfo: {
				key: "sentDate",
				order: "DESC",
			},
			currentPage: 1,
            totalBroadcasts: 0,
            pageSize: 10,
			broadcasts: [],
			completedColumns: [
				{
					title: "Broadcast",
					scopedSlots: { customRender: "name" },
					key: "name",
					sorter: true,
				},
				{
					title: "Sent",
					scopedSlots: { customRender: "sent" },
					key: "sentDate",
					sorter: true,
					defaultSortOrder: 'descend'
				},
				{
					title: "Total Sent",
					scopedSlots: { customRender: "delivered" },
					key: "delivered",
				},
				{
					title: "Total Unique Opens",
					scopedSlots: { customRender: "opened" },
					key: "opened",
				},
				{
					title: "Unique Open Rate",
					scopedSlots: { customRender: "openRate" },
					key: "openRate",
				},
				{
					title: "Click Through Rate",
					scopedSlots: { customRender: "ctr" },
					key: "ctr",
				},
				{
					title: "Bounce Rate",
					scopedSlots: { customRender: "bounceRate" },
					key: "bounceRate",
				},
				{
					title: "",
					scopedSlots: { customRender: "actions" },
					key: "actions",
					width: "5%",
				},
			],
		};
	},
	computed: {
		instances() {
			let result = [{
				id: this.$store.state.instance.id,
				name: this.$store.state.instance.name
			}]
			const children = this.$store.state.instance.children
			result.push(...children)

			return result;
		},

		columns() {
			let result = this.completedColumns
			if (this.instances && this.instances.length > 1) {
				result.splice(1, 0, {
					title: "Project",
					scopedSlots: { customRender: "instanceName" },
					key: "instanceName"
				})
			}

			return result;
		}
	},
	watch: {
        search() {
            this.searchDebounce();
        },
    },
	methods: {
		formatDate,

		searchDebounce: _.debounce(function () {
			this.currentPage = 1
            this.getBroadcasts();
            this.getTotalBroadcastCount();
        }, 1000),

		async getTotalBroadcastCount() {
            try {
                let searchQuery = "?sent=true&forReport=true";
                if (this.search) {
                    searchQuery += `&name_contains=${this.search}`;
                }

                let { data } = await this.$api.get(
                    `broadcasts/:instance/count${searchQuery}`
                );
                this.totalBroadcasts = data && data.count || 0;
            } catch (err) {
                if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while fetching broadcast count. Please try again.`))
				}
            }
        },
        async getBroadcasts() {
            this.loading = true;
            try {
                let start = (this.currentPage - 1) * this.pageSize;
                let searchQuery = "";
                if (this.search) {
                    searchQuery = `&name_contains=${this.search}`;
                }
                let { data } = await this.$api.get(
                    `/broadcasts/:instance?sent=true&forReport=true&_start=${start}&_limit=${this.pageSize}&_sort=${this.sortedInfo.key}:${this.sortedInfo.order}${searchQuery}`
                );
                this.broadcasts = data
            } catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while fetching broadcast list. Please try again.`))
				}
            }
            this.loading = false;
        },

		handleChange(pagination, filter, sorter) {
            if (pagination) {
                this.currentPage = pagination.current;
            }
            if (sorter && sorter.column) {
                if (
                    sorter.column.title === "Status" &&
                    sorter.order !== "descend"
                ) {
                    sorter.columnKey = "susspended";
                }
                this.sortedInfo = {
                    key: sorter.columnKey,
                    order: sorter.order === "descend" ? "DESC" : "ASC",
                };
            }
            this.getBroadcasts();
        },

		trim(str, len) {
			if (str.length > len) {
				return str.substring(0, len) + "...";
			} else {
				return str;
			}
		},
		bounceRate(record) {
			const temporary_fail = record.counts.temporary_fail || 0;
			const permanent_fail = record.counts.permanent_fail || 0;

			const totalFailed = temporary_fail + permanent_fail;

			if (totalFailed) {
				return (
					(totalFailed /
						(record.counts.totalSent || record.contacts)) *
					100
				).toFixed(2);
			}
			return 0;
		},

		getBroadcastName(id) {
			const broadcast = this.broadcasts.find(b => b.id === id)
			return broadcast ? broadcast.name : null;
		},

		previewEmail(html) {
			this.previewEmailModal = true;
			setTimeout(() => {
				var doc = document.querySelector("iframe.email-quick-preview")?.contentWindow?.document;
				if (doc) {
					doc.open();
					doc.write(html);
					doc.close();
				}
			}, 10);
		},

		getInstanceName(id) {
			if (this.instances) {
				const find = this.instances.find(i => i.id === id);
				if (find) {
					return find.name;
				}
				return "";
			}
			return "";
		}
	},
	created() {
		this.getBroadcasts();
		this.getTotalBroadcastCount();
	},
};
</script>

<style scoped>
.email-quick-preview {
	width: 100%;
	max-width: 700px;
	min-height: 500px;
	max-height: calc(100vh - 200px);
}
</style>
